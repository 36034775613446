<template>
	<button 
		class="btn d-flex align-items-center refresh-button-single"
		:class="(isModified) ? 'btn-warning' : 'btn-outline-warning'" 
		style="margin-left: 6px;"
		:disabled="isModified === false"
	>
		<div style="position: relative;left: -2px;">
			<font-awesome-icon icon="fa-solid fa-rotate-left" />
		</div>
	</button>
</template>

<script>
export default {
	name: "ResetButtonSingle",
	props: {
		isModified: Boolean,
	}
}
</script>

<style scoped>
.refresh-button-single {
  width: 24px !important;
  height: 24px !important;
  padding: 0 5px !important;
  border-radius: 4px;
}

</style>