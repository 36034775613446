<template>
	<button type="button" class="btn d-flex align-items-center lock-button"
		:class="(isLocked) ? 'btn-primary' : 'btn-outline-primary'"
		style="margin-left: 6px;"
	>
		<font-awesome-icon size="xs" :icon="(isLocked) ? 'fa-solid fa-lock' : 'fa-solid fa-lock-open'" />
	</button>
	<!--

		<button type="button" v-else class="btn btn-sm">
			<font-awesome-icon icon="fa-solid fa-lock-open" />
		</button>
	-->
</template>

<script>
export default {
	name: "LockButton",
	props: {
		isLocked: Boolean
	},
}
</script>

<style>
.lock-button {
  width: 24px !important;
  height: 24px !important;
  padding-left: 6px !important;
  border-radius: 4px!important;
	transition: none!important;
}
</style>