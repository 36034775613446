<template>
  <div v-if="isAwaitingResponse" id="spinner" class="d-flex justify-content-center align-items-center spinner-holder" style="position: fixed;z-index:10;">
    <!--

      <div class="spinner-border" style="width: 200px; height: 200px;position:absolute;"></div>
      <span style="position:relative;" class="">Please wait...</span>
    -->
    <GradientSpinner></GradientSpinner>
  </div>



  <div class="main-container">


    <div class="top-panel">
      <div>
        <img src="../../assets/HOLCIM_Logo_SmartCast_White.png" />
      </div>
      <div style="background-color: white; border-radius: 4px;">
        <button type="button" class="btn button-logout btn-outline-primary" @click="logOut()" tabindex="-1">Logout</button>
      </div>
    </div>

    <div style="position:relative;z-index:10; display:none;">
      <div id="roi-panel " class="d-flex mt-2 mb-2 roi-panel" :class="isROIPanelShown ? 'active': ''" style="">

        <ROICalculator :key="paramUpdates" :calibrationResponse="calibrationResponse" :overwriteParameters="tabDatas[1]" />
        <div class="d-flex justify-content-center align-items-start ms-1" @click="toggleROIPanel">
          <button type="button" class="btn btn-primary" style="height: 56px;">
            <font-awesome-icon v-if="isROIPanelShown" icon="fa-solid fa-chevron-left" />
            <font-awesome-icon v-else icon="fa-solid fa-chevron-right" />
          </button>
        </div>
        
      </div>
    </div>

    <!-- Main Container -->
    <div class="container-fluid" style="width:90%;margin-top: 113px;margin-bottom:50px; padding-top: 100px;">
      <!-- Hidden for now, could be used later -->
      <!-- Project and Mix names -->
      <!--
      <div class="row g-0" style="height: 60px;">
        <div class="col-3">
          <label for="project-name">Project name</label>
          <input required type="text" id="project-name" class="form-control border border-dark" v-model="projectName">
        </div>
        <div class="col-3 offset-2">
          <label for="mix-name">Mix name</label>
          <input required type="text" id="mix-name" class="form-control border border-dark" v-model="mixName">
        </div>
      </div>

      <div class="row g-0 mt-2">
        <div class="col-10 offset-1">
          <hr>
        </div>
      </div>
      -->
  
      <!-- Tabs Row-->
      <div class="row g-0 mt-2" style="margin-top: 100px;">      
        <button type="button" v-for="(item, index) of tabLabels" :key="index" class="col-2 tab" :class="{ active: currentTab === index }"
          :disabled="index !== 0 && !finishedCalibration" @click="changeTab(index);">
          <div v-if="currentTab === index" class="top-border"></div>
          {{ item }}
        </button>
      </div>
      <!-- Main Content Row-->
      <div class="tab-content g-0 main-content" style="margin-top:-2px;margin-bottom: 40px;">
        <CalibrationTab v-if="currentTab === 0" :modelName="modelName" :isAstm="astm" :initialData="tabDatas[0]" @changeValue="tab1ValueChanged"
          @receivedResponse="storeResponse" 
          @calibrationStarted="finishedCalibration = false"
          @calibrationEnded="onCalibrationFinished"
          @startWaiting="isAwaitingResponse = true"
          @stopWaiting="isAwaitingResponse = false"/>
        <AdjustmentTab v-if="currentTab === 1" :key="adjustmentNumbers" :isAstm="astm" :overwriteParams="overwriteParams" :modelName="modelName"
          :curveType="curveType" :lastCalibrationPayload="calibrationPayload" :initialLockedParameters="lockedParameters"
          :lastCalibrationResponse="calibrationResponse"
          :lastAdjustmentResponse="lastAdjustmentResponse"
          @lockedParametersChanged="onLockedParametersChanged"
          @startWaiting="isAwaitingResponse = true"
          @stopWaiting="isAwaitingResponse = false"
          @changeValue="overwriteParameterChanged"
          @removeParam="removeOverwriteParam"
          @changeCurve="tab2CurveChanged"
          @changeModel="tab2ModelChanged"
          @receivedResponse="storeAdjustment"
          @requestSuccessToast="onSuccessToastRequest" />
        <PredictionTab v-if="currentTab === 2" 
          :modelName="modelName" 
          :curveType="curveType"
          :initialData="tabDatas[2]"
          :lastCalibrationResponse="calibrationResponse"
          :lastAdjustmentResponse="lastAdjustmentResponse"
          :overwriteParams="overwriteParams" 
          @startWaiting="isAwaitingResponse = true"
          @stopWaiting="isAwaitingResponse = false"
          />
      </div>

    </div >
    <!-- End of Main Container-->

    
  </div>
  <!-- Footer -->
  <div class="footer" style="position:relative;">
    <div style="position:relative">
      <span>Holcim Innovation Center 2023 - </span>
      <span style="color: darkblue; font-weight: bold;cursor:pointer;" @click="copyToClipBoard()"
        @mouseenter="openContactInfoPanel()" @mouseleave="callTimerForCloseContactInfoPanel()">Contact us</span>
        <ContactInfoPanel v-if="hoveringContactInfo" @mouseenter="openContactInfoPanel()"
        @mouseleave="callTimerForCloseContactInfoPanel()"></ContactInfoPanel>
    </div>
    <div class="footer-img">
      <img src="../../assets/holcim_logo.png">
      <img src="../../assets/holcim_title.png" style="margin-left:9px">
    </div>
  </div>

</template>

<script>
import CalibrationTab from "@/components/MainPage/CalibrationTab/CalibrationTab.vue";
import AdjustmentTab from './AdjustmentTab/AdjustmentTab.vue';
import PredictionTab from './PredictionTab/PredictionTab.vue';
import ROICalculator from './ROICalculator.vue';

import { googleLogout } from "vue3-google-login";
import { useStorage } from "vue3-storage";

import * as common from '@/scripts/common';
import GradientSpinner from "../Elements/GradientSpinner.vue";
import ContactInfoPanel from "../Elements/ContactInfoPanel.vue";

export default {
  name: 'MainPage',
  components: {
    CalibrationTab,
    AdjustmentTab,
    PredictionTab,
    ROICalculator,
    GradientSpinner,
    ContactInfoPanel,
  },
  created(){
    this.parameterAttributes = common.parameterAttributes;
  },
  data() {
    return {
      i: 0,
      isAwaitingResponse: false,
      finishedCalibration: false,
      isROIPanelShown: false,
      projectName: '',
      mixName: '',
      modelName: 'arrhenius',
      parameterAttributes: {},
      astm: false,
      tabLabels: ['Calibration', 'Adjustment', 'Prediction'],
      currentTab: 0,
      paramUpdates: 0,
      calibrationPayload: {
        arrhenius: {
          exponential: null,
          logarithmic: null
        },
        nurse_saul: {
          exponential: null,
          logarithmic: null
        }
      },
      calibrationResponse: {
        arrhenius: {
          exponential: null,
          logarithmic: null
        },
        nurse_saul: {
          exponential: null,
          logarithmic: null
        }
      },
      tabDatas: [
        [{
          id: 0,
          strengthInputs: {
            //'range': [[16, 2.0], [24, 6.0], [48, 15.0], [72, 20.0], [168, 25.0], [672, 32.0]],
            'range': [[null,null], [null,null], [null,null]], 
            'file': [],
          },
          selectedStrengthType: 'range',
          strengthFileName: '',
          temperatureInputs: {
            'fixed': [[0.0, 20.0], [48.0, 20.0]],
            'range': [[0.0, 20.0], [4.0, 40.0], [12.0, 40.0], [18.0, 20.0], [72.0, 20.0]],
            'file': [],
          },
          temperatureFileName: '',
          selectedTemperatureType: 'fixed',
        }],
        {
          arrhenius: {
            exponential: {
              ult_strength: null,
              tau: null,
              beta: null,
              a_Ea: null,
            },
            logarithmic: {
              a_log: null,
              b_log: null,
              a_Ea: null,
            }
          },
          nurse_saul: {
            exponential: {
              ult_strength: null,
              tau: null,
              beta: null,
              ns_datum: null,
            },
            logarithmic: {
              a_log: null,
              b_log: null,
              ns_datum: null,
            }
          }
        },
        [{
          id: 0,
          temperatureInputs: {
          'fixed': [[0.0, 20.0], [672.0, 20.0]],
          'range': [[0.0, 20.0], [4.0, 40.0], [12.0, 40.0], [18.0, 20.0], [72.0, 20.0]],
          'file': [],
        },
          temperatureFileName: '',
          selectedTemperatureType: 'fixed',
        }]
      ],
      curveType: 'exponential',
      overwriteParams: {
        arrhenius:{
          exponential: {},
          logarithmic: {},
        },
        nurse_saul: {
          exponential: {},
          logarithmic: {},
        },
      },
      lockedParameters: [],
      adjustmentNumbers: 0,
      lastAdjustmentResponse: {
        arrhenius: {
          exponential: null,
          logarithmic: null
        },
        nurse_saul: {
          exponential: null,
          logarithmic: null
        }
      },
      toastTimerID: undefined,
      hoveringContactInfo: false,
      closeContactInfoPanelTimerID: null,
    };
  },
  methods: {
    logOut() {
      const storage = useStorage();
      storage.removeStorageSync("exp_date");
      storage.removeStorageSync("is_logged");
      storage.clearStorageSync();
      googleLogout();
      this.$router.push('/');
    },

    changeTab(index) {
      this.currentTab = index;
    },

    tab1ValueChanged(value) {
      this.tabDatas[0] = value;
      if (this.finishedCalibration === true) {
        this.$toast.warning("Input data has been changed, recalibration will be needed.", { position: "top", duration: 4000, queue: false, maxToasts: 1 });
        this.toastTimerID = setTimeout(() => {this.toastTimerID = undefined}, 4000);
      }
      this.finishedCalibration = false;
      this.overwriteParams = {
        arrhenius:{
          exponential: {},
          logarithmic: {},
        },
        nurse_saul: {
          exponential: {},
          logarithmic: {},
        },
      },
      this.lastAdjustmentResponse= {
        arrhenius: {
          exponential: null,
          logarithmic: null
        },
        nurse_saul: {
          exponential: null,
          logarithmic: null
        }
      }

    },

    overwriteParameterChanged(key, value) {      
      //this.tabDatas[1][model][curve][key] = value;
      this.overwriteParams[this.modelName][this.curveType][key] = value
      this.paramUpdates += 1;
    },

    onLockedParametersChanged(parameters) {
      this.lockedParameters = parameters;
    },
    
    removeOverwriteParam(key){
      this.overwriteParams[this.modelName][this.curveType][key] = null;
      this.paramUpdates += 1;
    },

    tab2CurveChanged(value) {
      this.curveType = value;
    },

    tab2ModelChanged(value) {
      this.modelName = value;
      this.curveType = value === 'arrhenius' ? 'exponential' : 'logarithmic';
    },

    toggleROIPanel(){
      this.isROIPanelShown = !this.isROIPanelShown;
    },

    onCalibrationFinished(error = null){
      this.lockedParameters = [];
      this.finishedCalibration = true;
      if (!error) {
        this.currentTab = 1;
      }
    },

    onSuccessToastRequest(message){
      if (typeof this.toastTimerID !== 'number'){
        this.$toast.success(message, { position: "top", duration: 2000, queue: false, maxToasts: 1 });
        this.toastTimerID = setTimeout(() => {this.toastTimerID = undefined}, 2000);
      }
    },

    // receives a payload and the response, and store them according to the curve type used 
    storeResponse(payload, response) {
      /*
      for (let key in response.parameters) {
        let decimal = this.parameterAttributes[key].decimals;
        response.parameters[key] = parseFloat(response.parameters[key].toFixed(decimal));
      }
      */
      let functionType = payload["model"]["function_type"];
      let modelName = payload["model"]["model_name"];
      this.calibrationPayload[modelName][functionType] = payload;
      this.calibrationResponse[modelName][functionType] = response;
      this.paramUpdates += 1;
    },

    storeAdjustment(payload, response){
      let functionType = payload["model"]["function_type"];
      let modelName = payload["model"]["model_name"];
      this.tabDatas[1][modelName][functionType] = response.parameters;
      this.lastAdjustmentResponse[modelName][functionType] = response;
      this.adjustmentNumbers += 1;
    },

    openContactInfoPanel() {
      if (this.closeContactInfoPanelTimerID) {
        clearTimeout(this.closeContactInfoPanelTimerID);
      }
      this.closeContactInfoPanelTimerID = null;
      this.hoveringContactInfo = true
    },

    callTimerForCloseContactInfoPanel(immediate = false) {
      if (this.closeContactInfoPanelTimerID) {
        clearTimeout(this.closeContactInfoPanelTimerID);
      }
      this.closeContactInfoPanelTimerID = setTimeout(() => {
        this.hoveringContactInfo = false;
      }, (immediate === false) ? 200 : 5);
    },

    copyToClipBoard() {
      navigator.clipboard.writeText("hdcs-apps.support@holcim.com");
      this.$toast.success('Copied to clipboard!', { position: "top", duration: 2000 });
    },

  },
}


</script>

<style>
html {
  margin-left: calc(100vw - 100%);
  overflow-y: scroll;
  scrollbar-width: none; 
}

html::-webkit-scrollbar { 
    display: none;  /* Safari and Chrome */
}

.top-panel{
  position: absolute;
  top: 0;
  left: 0;
  height: 113px;
  width: 100%;
  background: linear-gradient(90deg, #94C22C 0%, #04BBF1 43.23%, #20BBEF 55.73%, #214773 100%);
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0px 6px 8px rgba(26, 67, 112, 0.1);
  padding: 0px 50px
}

#app {
  background-color: #F5F6FA;
}

.main-content {
  border-radius: 4px;
  background-color: #FFFFFF;
}

.tab {
  margin-bottom: 2px;
  border: solid 1px #BFB9B4;
  box-sizing: border-box;
  position: relative;
  width: 200px!important;
  height: 60px;
  cursor: pointer;
  background-color: rgb(230, 230, 230);
  z-index: 1;

  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  text-transform: uppercase;
  color: #1A4370;
}

.tab:disabled {
  
  color: #76706B;
  background-color: #E6E4E3;
}

.tab.active {
  border-top: none;
  border-bottom: none;
  border-left: none;
  background-color: white;
}

.tab-content {
  padding: 23px 0px;
}

.top-border{
  position: absolute;
  top: 0px;
  left: 0px;
  width: 200px;
  height: 4px;
  background-color: #1A4370 ;
}

.button-logout {
  padding: 15px 24px!important;
  border-radius: 2px!important;
  font-weight: bold!important;
}

.upload-hint {
  font-style: italic;
  color: grey;
  margin-left: 8px;
}

.file-input {
  margin-left: 8px;
  border-radius: 6px;
  padding: 6px 14px;
  background-color: #1A4370;
  color: white;
  transition: background-color 0.25s, color 0.25s;
}

.file-input:hover {
  color: white;
  background-color: #173c63;
}

.border-corner {
  border: solid 2px rgb(125, 125, 125);
  border-radius: 8px;
  padding: 4px;
}

/* test classes */
/* TODO remove scafold classes of rows */

.skeleton {
  border: 1px solid green;
  box-sizing: border-box;
}

.spinner-holder {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.66);
  color: white;
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 10;
}

.roi-panel {
  position: absolute;
  width: 650px;
  left: -628px;
  transition: left 0.5s;
}
.roi-panel.active {
  left:0px
}

.input-head {
  color: #1A4370;
  border: none;
  background-color: white;
  text-align: left;
  padding: 12px 12px 12px 21px !important;
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.input-head-text {
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  text-transform: uppercase;
}

.button-no-decoration {
  color: #1A4370;
  padding: 0;
  border: none;
  background: none;
}

.input-content {
  border-top: 1px solid #E6E4E3;
}

.input-line {
  border: 1px solid #E6E4E3;
  padding: 8px 8px;
}

.btn.btn-lg {
  border-radius: 4px;
}

.footer {
  margin-top: auto;
  height: 114px;
  width: 100%;
  background-color: white;
  display:flex;
  align-items: center;
  justify-content: center;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #76706B;
  box-shadow: 0px -6px 8px rgba(26, 67, 112, 0.1);
}

.footer-img {
  position:absolute;
  left:50px;
  height: 36px; 
}
</style>