import { createRouter, createWebHistory } from "vue-router";
import LoginScreen from "@/components/login/LoginScreen.vue";
import MainPage from '@/components/MainPage/MainPage.vue';
import guard from '../utils/guard';


const routes = [
  {
    path: "/",
    redirect: '/login',
  },
  {
    path: "/login",
    name: "loginScreen",
    component: LoginScreen,
    meta: {
      hideNavbar: true,
    }
  },
  {
    path: '/main',
    alias: '/main',
    name: 'mainpage',
    component: MainPage,
    meta: {
      middleware: guard,
    },
  },
]

// Creates a `nextMiddleware()` function which not only  runs the default `next()` callback but also triggers , the subsequent Middleware function.
function nextFactory(context, middleware, index) {
  const subsequentMiddleware = middleware[index];
  // If no subsequent Middleware exists,   the default `next()` callback is returned.
  if (!subsequentMiddleware) return context.next;

  return (parameters) => {
    // Run the default Vue Router `next()` callback first.
    context.next(parameters);
    // Then run the subsequent Middleware with a new   `nextMiddleware()` callback.
    const nextMiddleware = nextFactory(context, middleware, index, 1);
    subsequentMiddleware({ context, next: nextMiddleware });
  };
}


const router = createRouter({
  
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.meta.middleware) {
    const middleware = Array.isArray(to.meta.middleware)
      ? to.meta.middleware
      : [to.meta.middleware];

    const context = {
      from,
      next,
      router,
      to,
    };
    const nextMiddleware = nextFactory(context, middleware, 1);

    return middleware[0]({ context, next: nextMiddleware });
  }

  return next();
});

export default router;