<template>
  <span>Add new reading:</span>
  <form @submit.prevent="addReading()" class="row g-0 d-flex justify-content-around input-line mt-1">
    <div class="input-group" style="width: calc(50% - 32px);">
      <input type="number" ref="timeInput" min=0 step="any" class="form-control"
        :tabindex="1 + ((type === 'temperature') * 100) + (inputIndex * 2000)" :value="facadeValues.input.time"
        data-type="input" data-property="time" @input="handleInput" @focus="onFocus" @blur="onBlur">
      <div class="input-group-append">
        <div class="input-group-text square">h</div>
      </div>
    </div>

    <div class="input-group" style="width: calc(50% - 32px);">
      <input type="number" min="0" step="any" class="form-control"
        :tabindex="2 + ((type === 'temperature') * 100) + (inputIndex * 2000)" :value="facadeValues.input.value"
        data-type="input" data-property="value" @input="handleInput" @focus="onFocus" @blur="onBlur">
      <div class="input-group-append">
        <div v-if="type === 'strength'" class="input-group-text square mpa-label" id="basic-addon2">MPa</div>
        <div v-else class="input-group-text square mpa-label" id="basic-addon2" style="padding-left: 10px!important;">°C
        </div>
      </div>
    </div>
    <div class="d-flex justify-content-center align-items-center" style="width: 32px">
      <button type="submit" :tabindex="3 + ((type === 'temperature') * 100) + (inputIndex * 2000)"
        class="align-self-center btn btn-secondary btn-sm" style="color:white!important;" :disabled="hasInvalidInput">
        <font-awesome-icon icon="fa-solid fa-plus" />
      </button>
    </div>
  </form>

  <br>
  <div class="row g-0 d-flex justify-content-center mb-1">
    <div style="width: calc(50%);text-align: center;"><b>Time (h)</b></div>
    <div style="width: calc(50%);text-align: center;"><b style="margin-left: 8px; margin-right: 44px;">{{ type ===
      "strength" ? "Strength (MPa)" : "Temperature (°C)" }}</b></div>
  </div>
  <div v-for="item, index of initialData" :key="index" class="row g-0 d-flex justify-content-around input-line">
    <div class="input-group" style="width: calc(50% - 32px);">
      <!--
        <input type="number" :id="'range-input-time' + type + '-' + index" min=0 step=0.1 class="form-control"
        :tabindex="getTabIndex(index) + 1" :value="isNaN(item[0]) ? null : item[0]"
        :disabled="item[0] === null && index < 3" @blur="onTimeInputBlur($event)" @change="onTimeChanged($event, index)"
        :data-index="index">
        -->
      <input type="number" :id="'range-input-time' + type + '-' + index" min=0 step=0.1 class="form-control"
        :tabindex="getTabIndex(index) + 1" :disabled="item[0] === null && index < 3"
        :value="getLogFacade(index, 'time')" 
        @focus="onFocus" @blur="onTimeInputBlur($event); onBlur($event); "
        @input="handleInput"
        @change="onTimeChanged($event, index)"
        :data-index="index" data-type="logs" data-property="time"
        >
      <div class="input-group-append">
        <div class="input-group-text square">h</div>
      </div>
    </div>
    <div class="input-group" style="width: calc(50% - 32px);">
      <!--
        <input type="number" :id="'range-input-value' + type + '-' + index" min="0" step=0.1 class="form-control"
        :tabindex="getTabIndex(index) + 2" @keydown.up.prevent="" @keydown.down.prevent=""
        @change="onValueChanged($event, index)" :value="isNaN(item[1]) ? null : item[1]" :disabled="item[0] === null">
        -->
        
        <input type="number" :id="'range-input-value' + type + '-' + index" min=0 step=0.1 class="form-control"
        :tabindex="getTabIndex(index) + 2" @keydown.up.prevent="" @keydown.down.prevent=""
        :disabled="item[0] === null"
        :value="getLogFacade(index, 'value')" 
        @focus="onFocus" @blur="onBlur($event)"
        @input="handleInput"
        @change="onValueChanged($event, index)"
        :data-index="index" data-type="logs" data-property="value"
        >
      <div class="input-group-append ">
        <div v-if="type === 'strength'" class="input-group-text square mpa-label" id="basic-addon2">MPa</div>
        <div v-else class="input-group-text square mpa-label" id="basic-addon2" style="padding-left: 10px!important;">°C
        </div>
      </div>
    </div>
    <div class="d-flex justify-content-center" style="width:32px;">
      <button type="button" class="align-self-center btn btn-danger btn-sm" style="border-radius:  2px!important;"
        @click="deleteReading(index)" :disabled="initialData.length <= minimumReadingNumber">
        <font-awesome-icon icon="fa-solid fa-xmark" />
      </button>
    </div>
  </div>
</template>

<script>
import { nextTick } from 'vue';

export default {
  name: 'RangeInput',
  props: {
    type: String, // 'strength' or 'temperature'
    initialData: Object,
    inputIndex: Number,
  },
  emits: [
    'deleteRequested',
    'readingAdded',
    'readingChanged',
  ],
  mounted() {
    this.isMounting = true;
    this.createFacade()
    this.isMounting = false;
  },
  data() {
    return {
      isMounting: true,
      facadeValues: {
        input: { time: null, value: null },
        logs: [
          { time: null, value: null },
          { time: null, value: null },
          { time: null, value: null },
        ]
      },
      timeInput: null,
      valueInput: null,
      newTime: null, // store new time value of currently changed time
    }
  },
  computed: {
    minimumReadingNumber() {
      return this.inputIndex === 0 ? 3 : 1;
    },
    hasInvalidInput() {
      return (isNaN(parseFloat(this.timeInput)) || isNaN(parseFloat(this.valueInput)));
    },
  },
  methods: {
    createFacade() {
      this.facadeValues.logs = this.initialData.reduce((stack, current) => {
        const valueDecimals = this.type === 'strength' ? 1 : 2;
        const newLog = {
          time: current[0] === null ? null : parseFloat(current[0].toFixed(1)),
          value: current[1] === null ? null : parseFloat(current[1].toFixed(valueDecimals)),
        }
        return [...stack, newLog]
      }, [])
    },

    onFocus(event) {
      const el = event.target;
      const realValue = this.getRealValue(el);
      const inputType = el.dataset.type;
      const inputProperty = el.dataset.property;
      if (inputType === 'input') {
        this.facadeValues[inputType][inputProperty] = realValue;
      } else {
        const logIndex = el.dataset.index;
        this.facadeValues[inputType][logIndex][inputProperty] = realValue;
      }
    },

    onBlur(event) {
      const el = event.target;
      const inputType = el.dataset.type;
      const inputProperty = el.dataset.property;
      const decimals = (inputProperty === 'value' && this.type === 'temperature') ? 2 : 1;
      const realValue = this.getRealValue(el);
      if (inputType === 'input') {
        this.facadeValues[inputType][inputProperty] = realValue !== null ? parseFloat(realValue.toFixed(decimals)) : null;
      } else {
        const logIndex = el.dataset.index;
        this.facadeValues[inputType][logIndex][inputProperty] = realValue !== null ? parseFloat(realValue.toFixed(decimals)) : null;
      }
    },

    handleInput(event) {
      const el = event.target;
      const inputType = el.dataset.type;
      const inputProperty = el.dataset.property;
      let newValue = parseFloat(el.value);
      // reset input value to previous one if new value is invalid (not a number)
      if (isNaN(newValue) && el.value !== '') {
        el.value = this.getRealValue(el);
        return;
      }
      /*
      if (inputProperty === 'time') {
        this.newTime = parseFloat(newValue);
      }
        */

      if (inputType === 'input') {
        this.facadeValues[inputType][inputProperty] = newValue;
        if (inputProperty === 'time') {
          this.timeInput = newValue;
        } else {
          this.valueInput = newValue;
        }
      } else {
        const logIndex = el.dataset.index;
        this.facadeValues[inputType][logIndex][inputProperty] = newValue;
      }

    },
    getRealValue(element) {
      const inputType = element.dataset.type;
      const inputProperty = element.dataset.property;
      if (inputType === 'input') {
        return inputProperty === 'time' ? this.timeInput : this.valueInput;
      } else {
        const logIndex = element.dataset.index;
        return inputProperty === 'time' ? this.initialData[logIndex][0] : this.initialData[logIndex][1];
      }
    },
    getLogFacade(index, property) {
      if (this.isMounting) {
        return null
      }
      return this.facadeValues.logs[index][property]
    },

    addReading() {
      const newReading = [this.timeInput, this.valueInput];
      this.timeInput = null;
      this.valueInput = null;
      this.facadeValues.input = { time: null, value: null };
      this.$emit("readingAdded", newReading);
      this.$refs.timeInput.focus();
    },
    getTabIndex(rowIndex) {
      let tabIndex = (rowIndex + 1) * 10 + this.inputIndex * 2000;
      if (this.type === 'temperature') {
        tabIndex += 100;
      }
      return tabIndex;
    },

    // triggered when time input element loses focus
    onTimeInputBlur(event) {
      const oldIndex = parseInt(event.target.dataset.index);
      let newIndex = 0;
      if (!this.newTime) {
        newIndex = oldIndex;
      } else {
        for (let i = 0; i < this.initialData.length; i++) {
          if (this.initialData[i][0] === this.newTime) {
            newIndex = i;
            break;
          }
        }
      }
      this.newTime = null;
      if (newIndex !== oldIndex) {
        // change focus only if user wants to add value after time (tabulation or click on value field of the reading)
        if (event.relatedTarget) {
          if (event.relatedTarget.id === `range-input-value${this.type}-${oldIndex}`) {
            // get element with new id(after sort), focus on it
            const el = document.getElementById(`range-input-value${this.type}-${newIndex}`);
            el.focus()
          }
        }
      }
    },
    onTimeChanged(event, index) {
      const newTime = parseFloat(event.target.value);
      this.newTime = newTime;

      this.$emit("readingChanged", index, [newTime, this.initialData[index][1]]);
      nextTick(() => {
        let newIndex = 0;
        for (let i = 0; i < this.initialData.length; i++) {
          if (this.initialData[i][0] === this.newTime) {
            newIndex = i;
            break;
          }
        }
        const el = document.getElementById(`range-input-time${this.type}-${newIndex}`);
        el.focus()
      })
    },
    onValueChanged(event, index) {
      const newValue = parseFloat(event.target.value);
      this.$emit("readingChanged", index, [this.initialData[index][0], newValue]);
    },
    deleteReading(index) {
      this.$emit("deleteRequested", index);
    },
  },

  watch: {
    initialData: {
      handler() {
        this.createFacade()
      },
      deep: true,
    }
  }
}
</script>