<template>
	<div>
		<input type="file" 
			:id="templateId" 
			:name="templateId"
			accept=".txt,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
			@change="$emit('fileChanged', $event)"
			style="display: none"
		>
		<div class="input-group">
			<label class="form-control d-flex align-items-center" style="cursor: pointer!important;" :for="templateId"> <span style="display:block;">{{ fileName || "No file selected" }}</span></label>

			<div class="input-group-append">
				<label class="btn btn-primary" :for="templateId" style="border-radius: 0 2px 2px 0;">Browse</label>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'FileInput',
	props: {
		usage: String,
		property: String,
		dataIndex: Number,
		fieldIndex: Number,
		fileName: String,
	},
	emits: [
		'fileChanged',
	],
	data() {
		return {};
	},
	computed: {
		templateId(){
			return `${this.usage}-${this.property}-${this.dataIndex}-${this.fieldIndex}-file-input`
		}
	},
}
</script>