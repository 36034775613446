<template>
  <form @submit.prevent="submit()" style="width:100%;margin: auto;" novalidate="true">
    <div style="padding: 0 30px;">
      <div class="row mt-1">
        <div class="col d-flex justify-content-between align-items-center">
          <!-- wrapper div tag for catching click input even if button is disabled-->
          <div class="col-3 tab-title">Calibration list</div>
          <div @click="addParameterInput()">
            <button type="button" class="btn btn-secondary add-button" :disabled="parameterDatas.length >= 3">
              <font-awesome-icon icon="fa-solid fa-plus" /> Add Calibration
            </button>
          </div>

        </div>
      </div>
      <CalibrationInput v-for="(item, index) in parameterDatas" :key="item.id" :index="index" :initialData="item"
        :isSingleParam="parameterDatas.length === 1" @requestDelete="deleteInputParam(index)"
        @changeValue="paramInputDataChanged" />
    </div>

    <hr>
    <div class="row mt-2" style="padding: 10px 30px;">
      <div class="col-12 d-flex align-items-center justify-content-end">
        <button type="button" @click="submit()" class="btn button-large btn-primary" style="border-radius: 2px!important;" 
          :disabled="isInvalid">
          Submit
        </button>
      </div>
    </div>

  </form>
</template>

<script>
import axios from 'axios';
import * as bootstrap from 'bootstrap';
import * as common from '@/scripts/common';

import CalibrationInput from './CalibrationInput.vue';

export default {
  name: 'CalibrationTab',
  components: {
    CalibrationInput,
  },
  emits: [
    'changeValue',
    'receivedResponse',
    'calibrationStarted',
    'calibrationEnded',
    'startWaiting',
    'stopWaiting',
  ],
  props: {
    modelName: String,
    isAstm: Boolean,
    initialData: Array,
  },
  mounted() {
    this.parameterDatas = [];
    setTimeout(() => {
      this.parameterDatas = this.initialData;
    }, 1);
    setTimeout(() => {
      this.toggleCollapsible(this.parameterDatas.length - 1)
    }, 10);

    this.paramCount = this.initialData[this.initialData.length - 1].id;
  },
  data() {
    return {
      paramCount: 0, // incremented at each new ParametersInput component; used as unique id to ensure display refresh
      parameterDatas: [{
        id: 0,
        strengthInputs: {
          'range': [[null, null], [null, null], [null, null]],
          'file': [],
        },
        selectedStrengthType: 'range',
        strengthFileName: '',
        temperatureInputs: {
          'fixed': [[0.0, 20.0], [48.0, 20.0]],
          'range': [[0.0, 20.0], [4.0, 40.0], [12.0, 40.0], [18.0, 20.0], [72.0, 20.0]],
          'file': [],
        },
        temperatureFileName: '',
        selectedTemperatureType: 'fixed',
      }],
      response: {
        exponential: null,
        logarithmic: null
      },

    }
  },
  computed: {
    isInvalid() {
      for (const param of this.parameterDatas) {
        if (param.selectedStrengthType === 'file' && (param.strengthFileName === '' || param.strengthInputs[param.selectedStrengthType].length < 3)) {
          return true;
        }
        for (let input of param.strengthInputs[param.selectedStrengthType]) {
          if (isNaN(parseFloat(input[0])) || isNaN(parseFloat(input[1]))) {
            return true;
          }
        }
        if (param.selectedTemperatureType === 'file' && (param.temperatureFileName === '' || param.temperatureInputs[param.selectedTemperatureType].length < 3)) {
          return true;
        }
      }
      return false;
    }
  },
  methods: {
    /*
    onDataChange() {
      console.log('on data change')
    },
    */

    addParameterInput() {
      if (this.parameterDatas.length >= 3) {
        this.$toast.warning('Cannot use more than 3 curves for calibration', { position: "top", duration: 2000 });
        return;
      }
      this.hideAll();
      this.paramCount += 1;
      this.parameterDatas.push({
        id: this.paramCount,
        strengthInputs: {
          'range': [[null, null]],
          'file': [],
        },
        selectedStrengthType: 'range',
        strengthFileName: '',
        temperatureInputs: {
          'fixed': [[0.0, 20.0], [48.0, 20.0]],
          'range': [[0.0, 20.0], [4.0, 40.0], [12.0, 40.0], [18.0, 20.0], [72.0, 20.0]],
          'file': [],
        },
        temperatureFileName: '',
        selectedTemperatureType: 'fixed',
      });
      // wait for 1 ms, then opens last calibrationInput component (the one we just created)
      setTimeout(() => {
        let lastIndex = this.parameterDatas.length - 1
        this.toggleCollapsible(lastIndex);
      }, 1);
      setTimeout(() => {
        const newElement = document.getElementById(`collapse-calib-${this.parameterDatas.length - 1}`);
        newElement.scrollIntoView();
      }, 100)
      //this.onDataChange();
      this.$emit('changeValue', this.parameterDatas);
    },
    deleteInputParam(index) {
      this.parameterDatas.splice(index, 1);
      this.$emit('changeValue', this.parameterDatas);
      //this.onDataChange();
    },
    paramInputDataChanged(index, data) {
      this.parameterDatas[index] = JSON.parse(JSON.stringify(data));
      this.$emit('changeValue', this.parameterDatas);
      //this.onDataChange();
    },

    // collapses all .collapse elements
    hideAll() {
      for (let i = 0; i < this.parameterDatas.length; i++) {
        let el = document.getElementById(`collapse-calib-${i}`);
        if (el.classList.contains('show')) {
          new bootstrap.Collapse(el)
        }
      }
    },

    toggleCollapsible(index) {
      let element = document.getElementById(`collapse-calib-${index}`);
      new bootstrap.Collapse(element);
    },

    async submit() {
      this.$emit('startWaiting');

      // build logs
      let logs = [];
      console.log(this.parameterDatas)
      this.parameterDatas.forEach(item => {
        let str = item.strengthInputs[item.selectedStrengthType];
        let temp = item.temperatureInputs[item.selectedTemperatureType];

        // if temperature is not from file, interpolate temperature between input
        if (item.selectedTemperatureType === "fixed" || item.selectedTemperatureType === "range") {
          let bridges = [];
          for (let i = 0; i < Object.keys(temp).length - 1; i++) {
            bridges.push(common.interpolateValues(temp[i], temp[i + 1]));
          }

          let interpolatedTemp = [[temp[0][0], temp[0][1]]];
          let i = 1;
          while (bridges.length > 0) {
            interpolatedTemp = interpolatedTemp.concat(...bridges.splice(0, 1)).concat([[temp[i][0], temp[i][1]]]);
            i += 1;
          }
          temp = interpolatedTemp;
        }

        // if needed, interpolate temperature from last temperature's time to last strength's Time
        let lastTemp = temp[temp.length - 1];
        let lastStr = str[str.length - 1];
        if (lastTemp[0] < lastStr[0]) {
          temp = temp.concat(common.interpolateValues(lastTemp, [lastStr[0] + 0.25, lastTemp[1]]));
        }

        logs.push({
          "id": `calibration_${item.id}`,
          "time_strength": str,
          "time_temp": temp,
        })
      });
      this.$emit('calibrationStarted');

      // for each curve type and each model, build payload and call lambda
      try {
        let arrheniusPayload = {
          "logs": logs,
          "parameters": {},
          "model": { "usage": "calibration", "model_name": "arrhenius", "function_type": "exponential" }
        }
        await this.callCalibrationLambda(arrheniusPayload);

        let nurseSaulPayload = {
          "logs": logs,
          "parameters": {},
          "model": { "usage": "calibration", "model_name": "nurse_saul", "function_type": "logarithmic" }
        }

        await this.callCalibrationLambda(nurseSaulPayload);
        this.$toast.success('Calibration done!', { position: "top", duration: 2000 });
      } catch (error) {
        this.$toast.error("Calibration has failed: please verify data or contact support", { position: "top", duration: 5000 });
        console.log(error)
        this.$emit('stopWaiting');
        this.$emit('calibrationEnded', error);
        return
      }
      this.$emit('stopWaiting');
      this.$emit('calibrationEnded');
    },

    async callCalibrationLambda(payload) {
      let response = await axios.post(this.$modelLambdaUrl, payload);
      if (!response) {
        throw new Error('Error during calculations');
      }

      /*
      if (response.data.domain_validity.domain_validity !== "OK") {
        throw new Error(response.data.domain_validity.error_messages.join("; "));
      }
      */
      const results = response.data.body.results
      // parse eventual string values
      for (let key in results.parameters) {
        if (typeof results.parameters[key] === "string") {
          results.parameters[key] = parseFloat(results.parameters[key]);
        }
      }
      // handle eventual misnamed fit_indicator key (should be r_squared)
      if ('fit_indicator' in results.parameters) {
        results.parameters["r_squared"] = results.parameters["fit_indicator"];
        delete results.parameters["fit_indicator"];
      }
      this.$emit('receivedResponse', payload, results);
    },

    /*
    interpolateValues(pointA, pointB, step = 0.25) {
      let values = [];
      let deltaX = pointB[0] - pointA[0]
      let deltaY = pointB[1] - pointA[1]
      for (let i = step - pointA[0] % step; i < deltaX; i += step) {
        let newX = pointA[0] + i;
        let newY = pointA[1] + deltaY * (i / deltaX);
        values.push([newX, newY]);
      }
      return values;
    }
    */

  }
}

</script>
