export var expFields = { 'ult_strength': 'Su', 'tau': '&tau;', 'beta': '&beta;' };
export var logFields = { 'a_log': 'a', 'b_log': 'b', };
export var parameterAttributes = {
  'ult_strength': { label: 'Su', unit: 'MPa', step: 1, decimals: 1, min: 0.01, max: 999, innerMin: 10, innerMax: 90, description:'Theoretical Maximum Strength' },
  'beta': { label: '&beta;', unit: '', step: 0.05, decimals: 2, min: 0.01, max: 999, innerMin: 0.3, innerMax: 20, description: 'Curvature Parameter' },
  'tau': { label: '&tau;', unit: '', step: 0.5, decimals: 1, min: 0.01, max: 999, innerMin: 1, innerMax: 100, description: 'Time Constant' },
  'a_log': { label: 'a', unit: '', step: 1, decimals: 1, min: -999, max: 20, innerMin: -300, innerMax: 20, description: 'Intercept'},
  'b_log': { label: 'b', unit: '', step: 1, decimals: 1, min: 10, max: 120, innerMin: 10, innerMax: 80, description: 'Curve Parameter' },
  'a_Ea': { label: 'Ea', unit: 'J/mol', step: 500, decimals: 0, min: 0, max: 999999, innerMin: 25000, innerMax: 60000, description: 'Activation Energy' },
  'ns_datum': { label: 'Datum', unit: '°C', step: 0.5, decimals: 1, min: -999, max: 999, innerMin: -10, innerMax: 0, description: 'Datum Teperature' },
};
export var miscParameterAttributes = {
  'cost': { label: 'Cost', unit: '€/&#13221;', step: 1, decimals: 2, min: 0, max: 9999 },
}

export var colors = [
  'rgb(0, 0, 255)',
  'rgb(50, 150, 50)',
  'rgb(255, 0, 0)',
];

export var calibrationLayout1 = {
  title: {
    text: 'Compressive strength',
    font: { size: 22, color: '#000' },
  },
  xaxis: {
    title: {
      text: 'Time (h)',
      font: { size: 18, color: '#7f7f7f' },
      standoff: 15,
    },
    rangemode: "tozero",
    range: [0, 200],
    showgrid: false,
  },
  yaxis: {
    title: {
      text: 'Compressive strength (MPa)',
      font: { size: 18, color: '#7f7f7f' },
    },
    rangemode: "tozero",
    range: [0, 200],
    showgrid: false,
    autotick: true,
    ticks: 'outside',
    tickcolor: '#FFF',
    tick0: 0,
    ticklen: 2,
    // dtick: 5,
  },
  automargin: true,
  margin: { autoexpand: false, l: 100, r: 100, t: 50, b: 50},
  width: 770,
  height: 400,
  showlegend: false,
  legend: {
    x: 0,
    y: -0.2,
    orientation: "h"
  }
};

export var calibrationLayout2 = {
  title: {
    text: 'Concrete temperature vs time',
    font: { size: 22, color: '#000' },
  },
  xaxis: {
    title: {
      text: 'Time (h)',
      font: { size: 18, color: '#7f7f7f' },
      standoff: 15,
    },
    rangemode: "tozero",
    showgrid: false,
  },
  yaxis: {
    title: {
      text: 'Concrete temperature (°C)',
      font: { size: 18, color: '#7f7f7f' },
    },
    rangemode: "tozero",
    showgrid: false,
    autotick: true,
    ticks: 'outside',
    tickcolor: '#FFF',
    tick0: 0,
    ticklen: 2,
    // dtick: 5,
  },
  automargin: true,
  margin: { autoexpand: false, l: 100, r: 100, t: 50, b: 50},
  width: 770,
  height: 400,
  showlegend: false,
  legend: {
    x: 0,
    y: -0.2,
    orientation: "h"
  }
};

export var adjustmentLayout1 = {
  title: {
    text: 'Calibration Curve',
    font: { size: 22, color: '#000' },
  },
  xaxis: {
    title: {
      text: 'Teq (h)',
      font: { size: 18, color: '#7f7f7f' },
      standoff: 15,
    },
    rangemode: "tozero",
    showgrid: false,
  },
  yaxis: {
    title: {
      text: 'Compressive strength (MPa)',
      font: { size: 18, color: '#7f7f7f' },
    },
    rangemode: "tozero",
    showgrid: false,
    autotick: true,
    ticks: 'outside',
    tickcolor: '#FFF',
    tick0: 0,
    ticklen: 2,
    // dtick: 5,
  },
  automargin: true,
  margin: { autoexpand: false, l: 100, r: 100, t: 100, b: 125},
  width: 770,
  height: 500,
  showlegend: true,
  legend: {
    x: 0,
    y: -0.2,
    orientation: "h"
  }
};

export var adjustmentLayout2 = {
  title: {
    text: 'Fitting visual assessment',
    font: { size: 22, color: '#000' },
  },
  xaxis: {
    title: {
      text: 'Time (h)',
      font: { size: 18, color: '#7f7f7f' },
      standoff: 15,
    },
    rangemode: "tozero",
    showgrid: false,
  },
  yaxis: {
    title: {
      text: 'Compressive strength (MPa)',
      font: { size: 18, color: '#7f7f7f' },
    },
    rangemode: "tozero",
    showgrid: false,
    autotick: true,
    ticks: 'outside',
    tickcolor: '#FFF',
    tick0: 0,
    ticklen: 2,
    // dtick: 5,
  },
  automargin: true,
  margin: { autoexpand: false, l: 100, r: 100, t: 100, b: 125},
  width: 770,
  height: 500,
  showlegend: true,
  legend: {
    x: 0,
    y: -0.2,
    orientation: "h"
  }
};

export var predictionLayout1 = {
  title: {
    text: 'Concrete temperature',
    font: { size: 22, color: '#000' },
    standoff: 15,
  },
  xaxis: {
    title: {
      text: 'Time (h)',
      font: { size: 18, color: '#7f7f7f' },
      standoff: 15,
    },
    rangemode: "tozero",
    showgrid: false,
  },
  yaxis: {
    title: {
      text: 'Concrete temperature (°C)',
      font: { size: 18, color: '#7f7f7f' },
    },
    rangemode: "tozero",
    showgrid: false,
    autotick: true,
    ticks: 'outside',
    tickcolor: '#FFF',
    tick0: 0,
    ticklen: 2,
    // dtick: 5,
  },
  automargin: true,
  margin: { autoexpand: false, l: 100, r: 100, t: 100, b: 100},
  width: 770,
  height: 500,
  showlegend: true,
  legend: {
    x: 0,
    y: -0.2,
    orientation: "h"
  }
};

export var predictionLayout2 = {
  title: {
    text: 'Compressive strength prediction',
    font: { size: 22, color: '#000' },
    standoff: 15,
  },
  xaxis: {
    title: {
      text: 'Time (h)',
      font: { size: 18, color: '#7f7f7f' },
      standoff: 15,
    },
    rangemode: "tozero",
    showgrid: false,
  },
  yaxis: {
    title: {
      text: 'Compressive strength (MPa)',
      font: { size: 18, color: '#7f7f7f' },
    },
    rangemode: "tozero",
    showgrid: false,
    autotick: true,
    ticks: 'outside',
    tickcolor: '#FFF',
    tick0: 0,
    ticklen: 2,
    // dtick: 5,
  },
  automargin: true,
  margin: { autoexpand: false, l: 100, r: 100, t: 100, b: 100},
  width: 770,
  height: 500,
  showlegend: true,
  legend: {
    x: 0,
    y: -0.2,
    orientation: "h"
  }
};

export const interpolateValues = (pointA, pointB, step = 0.25) => {
  let values = [];
  let deltaX = pointB[0] - pointA[0]
  let deltaY = pointB[1] - pointA[1]
  for (let i = step - pointA[0] % step; i < deltaX; i += step) {
    let newX = pointA[0] + i;
    let newY = pointA[1] + deltaY * (i / deltaX);
    values.push([newX, newY]);
  }
  return values;
}

// return axis steps of plotlyjs depending on maximum value
export const getGraphStep = (maxValue) => {
  if (maxValue > 700) {
    return 200;
  } else if (maxValue > 369.25) {
    return 100;
  } else if (maxValue > 147.5) {
    return 50;
  } else if (maxValue > 73.75) {
    return 20;
  } else if (maxValue > 36) {  
    return 10;
  } else if (maxValue > 10.5) {
    return 5;
  } else if  (maxValue > 2) {
    return 2;
  } else {
    return 1;
  }
}

// take 2D array in format [ [time0, value0], [time1, value1] , ...]
// sorts elements by comparing the first item (assumed to be time)
export const sortByTime = (array) => {
  return array.sort((a, b) => {
    if ( a[0] < b[0] || Number.isNaN(parseFloat(b[0])) ) { return -1}
    if ( a[0] > b[0] || Number.isNaN(parseFloat(a[0])) ) { return 1}
    if (a[1] <= b[1]) { return -1 }
    else { return 1}
  });
}