<template>
  <div class="wrapper-panel">
    <div class="row g-0">
      <h2 class="text-center">Calculator</h2>
    </div>

    <!-- Concrete parameters row -->
    <div class="skeleton">
      <div class="row g-0">
        <div class="col-12 d-flex align-items-center">
          <div class="flex-shrink-1 d-flex align-items-center justify-content-center bubble">
            <span>1</span>
          </div>
          <div style="margin-left: 10px;">
            Concrete parameters
          </div>
        </div>
      </div>

      <div class="row g-0">
        <div class="d-flex justify-content-between">
          <div class="d-flex flex-column" v-for="(item, key) in concreteParameters" :key="key"
            style="margin-left: 3px;margin-right: 3px;">
            <span> {{ labels[key] }} </span>
            <input type="number" class="form-control" v-model="concreteParameters[key]">
            <span class="text-secondary">
              {{ (key === "cost") ? '€/&#13221;' : '' }}
              {{ (key === "temperature") ? '°C' : '' }}
            </span>
          </div>
        </div>
      </div>
    </div>

    <!-- Operation parameters row -->
    <div class="skeleton mt-2">
      <div class="row g-0">
        <div class="col-12 d-flex align-items-center">
          <div class="flex-shrink-1 d-flex align-items-center justify-content-center bubble">
            <span>2</span>
          </div>
          <div style="margin-left: 10px;">
            Operation parameters
          </div>
        </div>
      </div>

      <div class="row g-0">
        <div class="d-flex col-10 justify-content-between">
          <div class="d-flex flex-column" v-for="(item, key) in operationParameters" :key="key"
            style="margin-left: 3px;margin-right: 3px;">
            <span>
              {{ (key === "volumePerCycle") ? "&#13221; per cycle" : labels[key] }}
            </span>
            <input type="number" class="form-control" v-model="operationParameters[key]">
            <span class="text-secondary">
              {{ (key === "targetStrength") ? 'MPa' : '' }}
              {{ (key === "dailyCost") ? '€/day' : '' }}
            </span>
          </div>
        </div>
      </div>
    </div>

    <div class="row g-0 mt-2 ">

      <div class="col-4 d-flex flex-column">
        <span>
          Normal cycle duration (h):
        </span>
        <input type="number" class="form-control" v-model="normalCycleHours">
      </div>
      <div class="col-4">
        <span>
          Normal cost (€) 
        </span>
        <input readonly :value="getNormalCost" type="number" class="form-control"/>
      </div>
      <div class="col-4 d-flex justify-content-center align-items-center">
        <button type="button" class="btn btn-primary"  @click="submit()">Submit</button>
      </div>
    </div>

    <div v-if="results.length > 0" class="row g-0 skeleton">
      <div class="col-10 ">

        <table class="table">
          <thead>
            <tr>
              <th scope="col"></th>
              <th scope="col">Days per cycle</th>
              <th scope="col">Total duration days</th>
              <th scope="col">Cost per cycle</th>
              <th scope="col">Total cost</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item, index of results" :key="index">
              <th scope="row"> #{{ index + 1 }}</th>
              <td>{{ item.daysPerCycle.toFixed(2) }}</td>
              <td>{{ item.totalDays.toFixed(2) }}</td>
              <td>{{ item.costPerCycle.toFixed(2) }}</td>
              <td>{{ item.totalCost.toFixed(2) }}</td>
            </tr>
          </tbody>
        </table>
      </div>

      <div class="col-2">
        <table class="table">
          <thead>
            <tr>
              <th scope="col">Money saved</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item, index of results" :key="index">
              <td :class="(getNormalCost - item.totalCost) > 0 ? 'green' : 'red'">{{ (getNormalCost -
                item.totalCost).toFixed(2) }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>



  </div>
</template>

<script>
export default {
  name: 'ROICalculator',
  components: {},
  props: {
    calibrationResponse: Object,
    overwriteParameters: Object,
  },
  mounted() {
    if (this.calibrationResponse["arrhenius"]["exponential"]) {
      this.concreteParameters.beta = this.calibrationResponse["arrhenius"]['exponential'].parameters.beta;
      this.concreteParameters.tau = this.calibrationResponse["arrhenius"]['exponential'].parameters.tau;
      this.concreteParameters.ult_strength = this.calibrationResponse["arrhenius"]['exponential'].parameters.ult_strength;
      this.concreteParameters.a_Ea = this.calibrationResponse["arrhenius"]['exponential'].parameters.a_Ea;

      for (const [parameter, value] of Object.entries(this.overwriteParameters["arrhenius"]["exponential"])) {
        if (value) {
          this.concreteParameters[parameter] = value;
        }
      }
    }

  },
  data() {
    return {
      labels: {
        cost: "Cost", beta: "Beta", tau: "Tau", ult_strength: "Su", a_Ea: "Ea", temperature: "Temperature",
        targetStrength: "Target CS", cyclesNumber: "Cycles", volumePerCycle: "&#13221; per cycle", dailyCost: "Site cost"
      },
      concreteParameters: {
        cost: 5,
        beta: 1.1,
        tau: 24,
        ult_strength: 40,
        a_Ea: 34500,
        temperature: 22,
      },
      operationParameters: {
        targetStrength: 15,
        cyclesNumber: 4,
        volumePerCycle: 100,
        dailyCost: 1000,
      },
      normalCycleHours: 35.0,
      results: [],
    }
  },
  computed:{
    getNormalCost() {
      let volumicCycleCost = this.operationParameters.volumePerCycle * this.concreteParameters.cost;
      let total = ((this.normalCycleHours / 24.0 * this.operationParameters.dailyCost) + volumicCycleCost) * this.operationParameters.cyclesNumber
      return total.toFixed(2);
    }
  },
  methods: {
    submit() {
      this.results = [];
      if (!this.calibrationResponse["arrhenius"]['exponential']) {
        let hours = this.getTimeFromConstantTemperature(this.concreteParameters.temperature);
        this.results.push(this.computeResultFromHours(hours));
      } else {
        let { calib_curve, ...rest } = this.calibrationResponse["arrhenius"]['exponential'].calibration_curves_teq;
        rest
        for (let item of calib_curve.time_strength) {
          if (item[1] >= this.operationParameters.targetStrength) {
            let hours = item[0];
            this.results.push(this.computeResultFromHours(hours));
            break;
          }
        }
      }


      /*
      this.results = []
      let data = this.calibrationResponse.calibration_curves_time;
      for(let key in data){
        for(let item of data[key].predicted_strength){
          // checks if strength (item[1]) is greater than target strength. In that case, stores the time value.
          if(item[1] >= this.operationParameters.targetStrength){
            let days = item[0]/24.0;
            let volumicCycleCost = this.operationParameters.volumePerCycle * this.concreteParameters.concreteCost;
            let newItem = {
              hours: item[0],
              daysPerCycle: days,
              totalDays: days * this.operationParameters.cyclesNumber,
              costPerCycle: (days * this.operationParameters.dailyCost) + volumicCycleCost,
              totalCost: ((days * this.operationParameters.dailyCost) + volumicCycleCost) * this.operationParameters.cyclesNumber
            }
            this.results.push(newItem)
            break;
          }
        }
      }
      */
    },
    getTimeFromConstantTemperature(temperature) {
      let logStrength = Math.log(this.concreteParameters.ult_strength / this.operationParameters.targetStrength);
      let betaInverse = 1 / this.concreteParameters.beta;
      let maturity = this.concreteParameters.tau / (logStrength ** betaInverse);
      let temperatureReduce = (this.concreteParameters.a_Ea / 8.314) * (1 / 293 - 1 / (273 + temperature));
      let hours = maturity / (Math.exp(temperatureReduce));
      return hours
    },
    getTimeFromVariableTemperature() {
      return 1;
    },
    computeResultFromHours(hours) {
      let days = hours / 24.0;
      let volumicCycleCost = this.operationParameters.volumePerCycle * this.concreteParameters.cost;
      return {
        hours: hours,
        daysPerCycle: days,
        totalDays: days * this.operationParameters.cyclesNumber,
        costPerCycle: (days * this.operationParameters.dailyCost) + volumicCycleCost,
        totalCost: ((days * this.operationParameters.dailyCost) + volumicCycleCost) * this.operationParameters.cyclesNumber,
        
      };
    },
  },
}
</script>

<style>
.bubble {
  box-sizing: border-box;
  border: solid 1px black;
  min-width: 32px;
  min-height: 32px;
  border-radius: 50%;
}

.wrapper-panel {
  box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.15);
  background-color: white;
  border: solid 1px;
  padding: 4px;
}

.red {
  color: red;
}

.green {
  color: green
}
</style>