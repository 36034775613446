<template>
  <router-view />
</template>

<script>
import { googleLogout } from "vue3-google-login";
import { useStorage } from "vue3-storage";
import router from "./router/router";

export default {
  name: 'App',
  methods: {
    logoutFunction: () => {
      const storage = useStorage();
      storage.removeStorageSync("exp_date");
      storage.removeStorageSync("is_logged");
      storage.clearStorageSync();
      googleLogout()
      router.push('/');
    }
  }
}
</script>

<style>
/*
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
*/
#app {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  min-height: 100vh;
  color: #0A1828;
}



.tab-title {
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  color: #1A4370;
}

.add-button {
  color: white!important;
  padding: 7px 12px 7px 15px;
  border-radius: 2px;
}

.add-button-small {
  color: white!important;
  padding: 10px 22px;
  border-radius: 2px;
}

/* remove arrows of inputs */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input.form-control, label.form-control {
  box-sizing: border-box;
  background: #FFFFFF;
  border: 1px solid #444445;
  border-radius: 2px;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  color: #0A1828;
}

input.form-control:read-only {
  background: #F5F6FA!important;
}

.input-group-append {
  display: flex!important;
  justify-content: center;
  align-items: center;
}

.input-group-append > .input-group-text{
  border-radius: 0 2px 2px 0;
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  text-align: center;
  border: 1px #444445 solid;
  height: 38px;
}

.input-group-text.square {
  width: 38px;
}

.button-large {
  padding: 15px 24px!important;
  border-radius: 4px!important;
  font-weight: 700!important;
}



.mpa-label {
  padding-top: 6px!important;
  padding-left: 6px!important;
}

.skeleton {
  box-sizing: border-box;
  border: 1px red solid;
}

.btn-secondary{
  border-radius: 2px!important;
}

.input-title {
  font-size: 13px;
}
</style>
