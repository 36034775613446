import * as XLSX from 'xlsx';

export const parseCSV = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = res => {
      resolve(res.target.result);
    };
    reader.onerror = err => reject(err);

    reader.readAsBinaryString(file);
  });
};

// parses file, removes column names if they exist, and returns content as array of array
export const parseXLSX = async (file) => {
  let rows = [];
  try{
    const data = await file.arrayBuffer();
    const workbook = XLSX.read(data)
    let worksheet = workbook.Sheets[workbook.SheetNames[0]]; // get first sheet of workbook
    rows = XLSX.utils.sheet_to_json(worksheet, {header: 1}) // parse values, returns as array of arrays
  } catch (error) {
    throw new Error(error);
  }
  if (rows.length === 0){
    throw new Error('No usable data in provided file');
  }
  // remove column names if exist
  if (typeof rows[0][0] !== "number" || typeof rows[0][1] !== "number"){
    rows.splice(0,1);
  }
  // remove all trailing empty rows
  while(rows.length > 0 && (rows[rows.length - 1].length === 0)){
    rows.pop();
  }
  rows.sort(compareXYpairs);
  return rows;
};

// received an file rows as array of array, purged from optionnal column headers
// expects only numerical values, throws error if encounters string value
export const checkFileData = (rows, dataType = "temperature") => {
  const rowsCount = rows.length;
  if (rowsCount < 3) {
    throw new Error("Too few data provided. At least 3 readings are needed.");
  }
  for (let i = 1; i < rowsCount; i++){
    if (typeof rows[i][0] !== "number" || isNaN(rows[i][0])){
      throw new Error(`Unexpected non-numeric value`);
    }
    if (typeof rows[i][1] !== "number" || isNaN(rows[i][1])){
      throw new Error(`Unexpected non-numeric value`);
    }
    if (rows[i][0] < 0.0 || rows[i][0] > 2184.0){ // check out of range time
      throw new Error('Time is out of allowed range [0.0, 2184.0]');
    }
    if (dataType === "strength" && (rows[i][1] < 0.0 || rows[i][1] > 90.0)) { // check out of range strength
      throw new Error('Strength is out of allowed range [0.0, 90.0]');
    }
    if (dataType === "temperature" && (rows[i][1] < -15.0 || rows[i][1] > 80.0)) { // check out of range temperature
      throw new Error('Temperature is out of allowed range [-15.0, 80.0]');
    }
    /* unused, rows are now sorted
    if (i > 0 ){ 
      if (rows[i][0] < rows[i-1][0]){ // check time not strictly growing sequence
        throw new Error('Time must be a strictly growing sequence');
      }
    }
    */
  }
}

export const parseTXT = async (file) => {
  
  try {
    // read file a plain text
    const text = await (new Response(file)).text();
    // split text on line break, parse obtained array and build a 2-dimension array
    let rows = text.split(/\r?\n|\r|\n/g)
    
    rows = rows.reduce((stack, current) => {
      if (current === ''){
        return stack;
      }
      const row = current.split('\t'); // split on tabulations
      const x = parseFloat(row[0].replace(',', '.')); // replace coma decimal marker to point
      const y = parseFloat(row[1].replace(',', '.'));
      return [...stack, [x, y]];
    }, []);
    // remove NaN (from parseFloat of non-numeric) if exist
    if (isNaN(rows[0][0]) && isNaN(rows[0][1])){
      rows.splice(0,1);
    }
    // remove all trailing empty rows
    while(rows.length > 0 && (rows[rows.length - 1].length === 0)){
      rows.pop();
    }
    // sometimes, x values are unsorted. sorting the rows array based on x value is needed
    rows.sort(compareXYpairs);

    return rows;
  } catch (error) {
    throw new Error(error);
  }
}

export const extractFromCSV = (data, rows, columns) => {
  let lines = data.split("\n");
  let results = {}
  for (let n = 0; n <= (columns[1] - columns[0]); n++) {
    results[n] = [];
  }

  // TODO check if data is not the cells it is supposed to be
  for (let i = rows[0]; i < rows[1]; i++) {
    let line = lines[i].split("\t");
    for (let j = 0; j <= (columns[1] - columns[0]); j++){
      results[j].push(Number(line[columns[j]]));
    }
  }
  return results;
};

export const extractFromXLSX = (data, rows, columns) => {
  let lines = data[0];
  const results = { 
    time_strength: [],
    time_temp: []
  }

  let hasStrengthData = true;
  let hasTempData = true;

  lines.forEach(line => {
    if(line[columns[0]] && line[columns[1]] && hasStrengthData){
      results.time_strength.push([line[columns[0]], line[columns[1]]])
    } else {
      hasStrengthData = false;
    }
    if(line[columns[2]] && line[columns[3]] && hasTempData){
      results.time_temp.push([line[columns[2]], line[columns[3]]])
    } else {
      hasTempData = false;
    }
    if (!hasStrengthData && !hasTempData){
      return results;
    }
  });
  return results;
}

function compareXYpairs(a, b) {
  if (a[0] < b[0]) { return -1 }
  if (a[0] > b[0]) { return 1 }
  if (a[0] === b[0]) {
    if (a[1] <= b[1]) { return -1 }
    else { return 1}
  }
}

/*
export const extractColumns = (data, columns) => {
  let lines = data[0];
  let time_strength = [];

  let i = 0;
  while(lines[i][columns[0]] && lines[i][columns[1]]){
    if (typeof lines[i][columns[0]] !== "number" || typeof lines[i][columns[1]] !== "number") {
      break;
    }
    time_strength.push([lines[i][columns[0]], lines[i][columns[1]]]);
    i += 1;
  }
  if (time_strength.length === 0) {
    throw new Error('Incorrect data format');
  }
  return time_strength;
}
*/