<template><!-- Adjusted parameters display -->
  <div style="padding: 0 30px;">
    <!-- Tab head -->
    <div class="row" >

      <div class="col-3 tab-title">Calibration parameters</div>

      <div class="d-flex flex-grow-1">
        <!-- All parameters depending on curve type -->
        <div v-for="param, key of curveParameters[curveType]" :key="key" class="d-flex" style="margin-right:20px;">
          <div class="col">

            <!-- label -->
            <div class="row g-0">
              <div v-html="getParameterLabel(param)"></div>
            </div>

            <div class="d-flex align-items-center">
              <!-- Input -->
              <div class="input-group">
                <input class="form-control d-inline" type="number" :value="getDisplayedValue(param)" readonly style="height:38px;">
                <div v-if="hasUnit(param)" class="input-group-append">
                  <span class="input-group-text" id="basic-addon2">{{ parameterAttributes[param].unit }}</span>
                </div>
              </div>
            </div>

          </div>
        </div>

        <!-- Reference Temperature -->
        <div style="margin-right: 20px;">
          <div class="col">

            <!-- label -->
            <div class="row g-0">
              <div>Reference Temperature</div>
            </div>

            <!-- Input -->
            <div class="d-flex align-items-center">
              <div class="input-group">
                <input class="form-control d-inline" type="number" :value="getDisplayedValue('T_ref')" readonly style="height:38px;">
                <div class="input-group-append">
                  <span class="input-group-text">°C</span>
                </div>
              </div>
            </div>

          </div>
        </div>

      </div>
    </div>
    <hr/>

    <!-- Inputs -->
    <div class="d-flex align-items-center justify-content-between " >
      <div class="tab-title">Temperature scenarios</div>
      <div @click="addPredictionInput()" class="mb-1">
        <button type="button" class="btn btn-secondary add-button" :disabled="parameterDatas.length >= 3">
          <font-awesome-icon icon="fa-solid fa-plus" /> Add temperature scenario
        </button>
      </div>
    </div>
    <form @submit.prevent="submit()" style="width:100%;margin: auto;" novalidate="true">

      <PredictionInput v-for="(item, index) in parameterDatas" :key="item.id" :index="index" :initialData="item" :isSingleParam="parameterDatas.length === 1"
        @requestDelete="deletePredictionParam(index)" @changeValue="predictionInputDataChanged" />
    </form>

    <div class="row g-0 mt-4 mb-4">
      <div class="col-12 d-flex align-items-center justify-content-end">
        <button type="button" class="btn btn-lg btn-primary submit-button" style="border-radius: 2px;" @click="submit" :disabled="hasInvalidValue">
          Submit
        </button>
      </div>
    </div>

  </div>

  <hr/>

  <!-- Graphs -->
  <div class="row g-0 mt-2">
    <div class="d-flex mt-2 justify-content-around" style="position:relative">

      <div class="d-flex">
        <div id="prediction-graph1" style="width:45%;">
        </div>
        <!-- Unused
          <div id="prediction-graph2" style="width:45%;">
          </div>
        -->
      </div>

    </div>
  </div>
</template>

<script>
import axios from 'axios';
import Plotly from 'plotly.js-dist-min';
import * as bootstrap from 'bootstrap';
import * as common from '@/scripts/common';

import PredictionInput from './PredictionInput.vue'

export default {
  name: 'PredictionTab',
  components: {
    PredictionInput,
  },
  props: {
    modelName: String,
    curveType: String,
    initialData: Array,
    lastCalibrationResponse: Object,
    lastAdjustmentResponse: Object,
    overwriteParams: Object,
  },
  emits: [
    'startWaiting',
    'stopWaiting'
  ],
  created() {
    this.parameterAttributes = common.parameterAttributes;
    this.expFields = common.expFields;
    this.logFields = common.logFields;
  },
  mounted() {
    this.parameterDatas = [];
    setTimeout(() => {
      this.parameterDatas = this.initialData;
      /* move to PredicitonInput
      this.buildTemperatureGraph();
      */
    }, 1);
    setTimeout(() => {
      this.toggleCollapsible(this.parameterDatas.length -1)
    }, 10);

    this.paramCount = this.initialData[this.initialData.length - 1].id;
    this.buildStrengthGraph();
  },
  data() {
    return {
      // apiURL: "https://dnmizr2f4k.execute-api.eu-west-1.amazonaws.com/default/aws_maturity_handler", // old
      // apiURL: "https://zmy45ka7ftxxoesb6ifcohpiye0gjezu.lambda-url.eu-west-1.on.aws/", // dev
      // apiURL: "https://gimrqs4xwthzkj33k63avleudy0tqtta.lambda-url.eu-west-1.on.aws/", // prod
      curveParameters: {
        exponential: ["ult_strength","beta", "tau", "a_Ea"],
        logarithmic: ["a_log", "b_log", "ns_datum"],
      },
      responses: [],
      paramCount: 0,
      parameterDatas: [{
        id: 0,
        temperatureInputs: {
          'fixed': [[0.0, 20.0], [672.0, 20.0]],
          'range': [[0.0, 20.0], [4.0, 40.0], [12.0, 40.0], [18.0, 20.0], [72.0, 20.0]],
          'file': [],
        },
        temperatureFileName: '',
        selectedTemperatureType: 'fixed',
      }],
      expFields: {},
      logFields: {},
      parameterAttributes: {},
    };
  },
  computed: {
    hasInvalidValue(){
      for (let input of this.parameterDatas) {
        if (input.temperatureInputs[input.selectedTemperatureType].length == 0){
          return true;
        }
      }
      return false;
    }
  },
  methods: {
    hasUnit(key) {
      return this.parameterAttributes[key].unit !== '';
    },

    getDisplayedValue(key) {
      let overwriteValue = this.overwriteParams[this.modelName][this.curveType][key];
      const isModified = (overwriteValue !== null && overwriteValue !== undefined);

      if (isModified) {
        return this.overwriteParams[this.modelName][this.curveType][key];
      } else if (this.lastAdjustmentResponse[this.modelName][this.curveType] !== null) {
        return this.lastAdjustmentResponse[this.modelName][this.curveType]['parameters'][key];
      } else {
        return this.lastCalibrationResponse[this.modelName][this.curveType]['parameters'][key];
      }
    },

    getParameterLabel(key) {
      return this.parameterAttributes[key].label;
    },

    addPredictionInput() {
      if (this.parameterDatas.length >= 3) {
        this.$toast.warning('Cannot use more than 3 curves for prediction', { position: "top", duration: 2000 });
        return;
      }
      this.hideAll();
      this.paramCount += 1;
      this.parameterDatas.push({
        id: this.paramCount,
        temperatureInputs: {
          'fixed': [[0.0, 20.0], [672.0, 20.0]],
          'range': [[0.0, 20.0], [4.0, 40.0], [12.0, 40.0], [18.0, 20.0], [72.0, 20.0]],
          'file': [],
        },
        temperatureFileName: '',
        selectedTemperatureType: 'fixed',
      })
      setTimeout(() => {
        let lastIndex = this.parameterDatas.length - 1
        this.toggleCollapsible(lastIndex);
        document.getElementById(`collapse-predi-${lastIndex}`).scrollIntoView(false);
      }, 20);
    },

    deletePredictionParam(index) {
      this.parameterDatas.splice(index, 1);
    },

    predictionInputDataChanged(index, data) {
      this.parameterDatas[index] = JSON.parse(JSON.stringify(data));
      /* moved to PredictionInput
      this.buildTemperatureGraph();
      */
    },

    // collapses all .collapse elements
    hideAll() {
      for (let i = 0; i < this.parameterDatas.length; i++) {
        let el = document.getElementById(`collapse-predi-${i}`);
        if (el.classList.contains('show')) {
          new bootstrap.Collapse(el)
        }
      }
    },

    toggleCollapsible(index) {
      let element = document.getElementById(`collapse-predi-${index}`);
      new bootstrap.Collapse(element);
    },

    async submit() {
      const params = {}
      Object.keys(this.lastCalibrationResponse[this.modelName][this.curveType]['parameters']).map((item) => {
        params[item] = this.getDisplayedValue(item);
      })
      let { a_Ea, ns_datum, T_ref, ...rest } = params;

      this.responses = [];

      this.$emit('startWaiting');
      try {

        for (let item of this.parameterDatas) {
          // discretize temperature log
          let temp = item.temperatureInputs[item.selectedTemperatureType];
          // if temperature is not from file, interpolate temperature between input
          if (item.selectedTemperatureType === "fixed" || item.selectedTemperatureType === "range") {
            let bridges = [];
            for (let i = 0; i < Object.keys(temp).length - 1; i++) {
              bridges.push(common.interpolateValues(temp[i], temp[i + 1]));
            }

            let interpolatedTemp = [[temp[0][0], temp[0][1]]];
            let i = 1;
            while (bridges.length > 0) {
              interpolatedTemp = interpolatedTemp.concat(...bridges.splice(0, 1)).concat([[temp[i][0], temp[i][1]]]);
              i += 1;
            }
            temp = interpolatedTemp;
          }

          // buld payload
          let payload = {
            "logs": [{
              "id": `prediction_${item.id}`,
              "time_temp": temp,
            }],
            "parameters": {
              "maturity_parameters": (this.modelName === 'arrhenius') ? { a_Ea, T_ref } : { ns_datum, T_ref },
              "function_parameters": {
                ...rest
              }
            },
            "model": {
              "usage": "prediction",
              "model_name": this.modelName,
              "function_type": this.curveType
            }
          }

          for (let key in payload.parameters.function_parameters) {
            payload.parameters.function_parameters[key] = parseFloat(payload.parameters.function_parameters[key])
          }

          // call lambda
          this.$emit('startWaiting');
          let resp = await axios.post(this.$modelLambdaUrl, payload);

          if (!resp) {
            throw new Error('Server is not responding.');
          }
          if (resp.data.error_messages.length > 0) {
            throw new Error(`Server encountered an error. ${resp.data.error_messages.join(' ;\n')}`);
          }
          if (!resp.data.body.results) {
            throw new Error('Server response is empty.');
          }

          this.responses.push(resp.data.body.results);
        }

        this.$toast.success('Prediction done!', { position: "top", duration: 2000 });
      } catch (error) {
        this.$toast.error(error, { position: "top", duration: 2000 });
      }
      this.$emit('stopWaiting')
      this.buildStrengthGraph();
      document.getElementById("prediction-graph1").scrollIntoView();
      
    },

    buildStrengthGraph() {
      let strengthPlots = [];
      for (let item of this.responses) {
        let i = strengthPlots.length;
        strengthPlots.push({
          x: item.time,
          y: item.predicted_strength,
          mode: 'lines',
          line: { color: common.colors[i], width: 2 },
          name: `Scenario ${i + 1}`,
          text: item.time.reduce((stack, current) => { current; return [...stack, `Scenario ${i + 1}`] }, []),
          hoverinfo: "x+y+text",
        });
      }

      let maxStrength = 0;
      for (let data of strengthPlots){
        for (let log of data.y){
          if (log > maxStrength) {
            maxStrength = log;
          }
        }
      }
      common.predictionLayout2.yaxis.range = [0, maxStrength + common.getGraphStep(maxStrength)]

      Plotly.newPlot("prediction-graph1", {
        data: strengthPlots,
        layout: common.predictionLayout2,
        config: { responsive: true }
      });
    },

    
  }
}

</script>
